const RxApi = {
  verifyHealthCheck: async () => {
    const endpoint = 'healthcheck';
    const url = `${process.env.RX_API}/${endpoint}`;

    try {
      const response = await fetch(url);
      return await response.json();
    } catch (error) {
      console.error(`Error communicating with Rx API ${error}`);
    }
  },
};

export default RxApi;
