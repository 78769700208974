import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import styled from '@emotion/styled';

import { List, ListItem, NotificationsIcon } from '@warbyparker/retail-design-system';

import AppointmentSlot from './AppointmentSlot';
import AppointmentModal, { AppointmentModalProps } from './AppointmentModal';
import Error from '../shared-components/Error';

import { loading } from '../assets/styles/loading';

import {
  getDoctorAppointments,
  getDoctorUID,
  getAppointmentTypes,
} from '../redux/actions/pmp';
import { selectPatient } from '../redux/actions/rxEntry';
import { selectAddReading } from '../redux/actions/topcon';
import { noAppointments, table } from '../assets/styles/appointmentList';
import pmp from '../redux/selectors/PmpSelectors';
import { useAppSelector } from '../hooks';
import appContainerStyles from '../assets/styles/appContainer';
import StoreLocator from './StoreLocator';
import useActions from '../hooks/useActions';
import { HeliosUserPermissions } from '../utilities/constants';
import Button from '../shared-components/Button';
import COLORS from '../assets/styles/colors';

const MainContent = styled(List)`
  max-width: 720px;
`;

const Loading = () => (
  <>
    <div css={loading}>
      <img
        alt="spinner"
        src="https://www.warbyparker.com/assets/img/icons/actions/spinner.png"
      />
      <p>Loading&hellip;</p>
    </div>
  </>
);

const DefaultSlot: AppointmentModalProps['slot'] = {
  id: '',
  doctor: -1,
  duration: -1,
  exam_room: -1,
  office: '',
  patient: {
    first_name: '',
    last_name: '',
    id: null,
    cell_phone: '',
    date_of_birth: '',
    email: '',
  },
  profile: null,
  scheduled_time: '',
};

const AppointmentList = () => {
  const [modalApptIdx, setModalApptIdx] = useState<number | undefined>();
  const [modalOpen, setOpen] = React.useState(false);

  const {
    appointments,
    appointmentTypes,
    doctorUID,
    error,
    pending,
  } = useAppSelector(pmp.appointmentListSelector);
  const date = useAppSelector(state => state.pmp?.date);
  const token = useAppSelector(state => state.auth?.jwt);
  const shortName = useAppSelector(state => state.auth?.me?.facility?.short_name);
  const doctorName = useAppSelector(state => state.auth?.me?.user?.name);
  const features = useAppSelector(state => state.auth?.me?.features || ['']);
  const permissions = useAppSelector(state => state.auth?.me?.permissions || []);
  const userID = useAppSelector(state => state.auth?.me?.user?.id);

  const {
    connectedGetDoctorAppointments,
    connectedGetAppointmentTypes,
    connectedGetDoctorUID,
    connectedSelectPatient,
    connectedSelectAddReading,
  } = useActions({
    connectedGetDoctorAppointments: getDoctorAppointments,
    connectedGetAppointmentTypes: getAppointmentTypes,
    connectedGetDoctorUID: getDoctorUID,
    connectedSelectPatient: selectPatient,
    connectedSelectAddReading: selectAddReading,
  });

  useEffect(() => {
    connectedGetDoctorUID(userID, token);
  }, [connectedGetDoctorUID, token, userID]);

  useEffect(() => {
    if (doctorUID && date) {
      connectedGetDoctorAppointments(
        token,
        doctorUID,
        format(date, 'yyyy-MM-dd'),
      );
      connectedGetAppointmentTypes(shortName);
    }
  }, [connectedGetAppointmentTypes, connectedGetDoctorAppointments, date, doctorUID, shortName, token]);

  if (error) {
    return <Error error={error} />;
  }

  if (pending) {
    return <Loading />;
  }

  if (appointments && appointments.length === 0) {
    return (
      <div css={noAppointments}>
        <p>
          No appointments on
          {' '}
          {date ? format(date, 'iiii, LLLL do') : ''}
          {' '}
          for Dr.
          {' '}
          {doctorName}
          !
        </p>
      </div>
    );
  }

  const makeAppointmentSlot = (slot: AppointmentModalProps['slot']) => (
    <AppointmentSlot
      appointmentTypes={appointmentTypes}
      key={`slot_${slot.id}`}
      slot={slot}
      connectedSelectPatient={connectedSelectPatient}
      connectedSelectAddReading={connectedSelectAddReading}
      features={features}
      permissions={permissions}
    />
  );

  const onCloseModal = () => {
    setModalApptIdx(undefined);
    setOpen(false);
  };

  const toggleModal = (idx: number) => {
    setModalApptIdx(idx);
    setOpen(true);
  };

  return (
    <>
      <div css={appContainerStyles.widthLimiter}>
        <StoreLocator />
      </div>
      {permissions.includes(HeliosUserPermissions.OptometristRxEntryQuickActions) ? (
        <MainContent>{appointments && appointments.map((slot, idx: number) => (
          <ListItem key={`appt_${slot.id}`} onClick={() => toggleModal(idx)}>
            {makeAppointmentSlot(slot)}
          </ListItem>))}
        </MainContent>)
        : appointments &&
          (<table css={table}><tbody>{appointments.map(makeAppointmentSlot)}</tbody></table>)
      }
      <AppointmentModal
        appointmentTypes={appointmentTypes}
        slot={modalApptIdx !== undefined ? appointments[modalApptIdx] : DefaultSlot}
        onCloseModal={onCloseModal}
        connectedSelectAddReading={connectedSelectAddReading}
        connectedSelectPatient={connectedSelectPatient}
        open={modalOpen}
        allowTopconUpload={features.includes('topcon_upload_enabled')}
      />
    </>
  );
};

export default AppointmentList;
