/* stylelint-disable declaration-property-value-blacklist */
import React from 'react';

import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { colors, Modal, PrescriptionIcon, QuickAddIcon, ClockIcon, ReviewIcon, SettingsIcon } from '@warbyparker/retail-design-system';
import { convertToRegularTime } from '../utilities/formatters.util';
import { appointmentTypeLookup, SelectPatientPayload, SelectAddReadingPayload } from './AppointmentSlot';
import { ROUTE_ADD_RX, ROUTE_UPLOAD_READING } from '../routes';
import { AppointmentSlot, AppointmentType } from '../types';

const Details = styled.div`
  color: ${colors.charcoal};
`;

const PatientName = styled.div`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 12px;
  line-height: 32px;
`;

const ApptTime = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
  line-height: 22px;
`;

const ApptType = styled.div`
  font-size: 16px;
  line-height: 26px;
`;

const ActionContainer = styled.div`
  color: ${colors.charcoal};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 24px;
`;

const sharedModalActionStyles = `
  display: flex;
  align-items: center;
  text-align: left;
  min-width: 225px;
  font-size: 16px;
  font-weight: 400;
  color: inherit;
`;

const PatientAction = styled.button`
  ${sharedModalActionStyles};
  cursor: pointer;
  background: none;
  border: none;
  padding: 0px;
`;

const linkStyles = `
  ${sharedModalActionStyles}
  text-decoration: none;
  font-family: inherit;
  &:visited, &:hover, &:active {
    color: inherit;
  }
`;

const NavigationAction = styled.a(linkStyles);

const StyledLink = styled(Link)(linkStyles);

const Divider = styled.div`
  height: 1px;
  background-color: ${colors.silver};
  margin: 24px 0px;
`;

const ActionName = styled.div`
  font-size: 16px;
  line-height: 22px;
  margin-left: 12px;
`;

const IconWrapper = styled.div`
  display: flex;
  font-size: 24px;
  color: ${colors.stone};
`;

export interface AppointmentModalProps {
  slot: AppointmentSlot;
  appointmentTypes: AppointmentType[];
  onCloseModal(): void;
  connectedSelectPatient: (payload: SelectPatientPayload) => void;
  connectedSelectAddReading: (payload: SelectAddReadingPayload) => void;
  allowTopconUpload: boolean;
  open: boolean;
}

const toDrChronoUrl = (path: string) => `https://${process.env.DR_CHRONO_DOMAIN || ''}${path}`;

const AppointmentModal = ({
  slot,
  appointmentTypes,
  onCloseModal,
  connectedSelectPatient,
  connectedSelectAddReading,
  allowTopconUpload,
  open,
}: AppointmentModalProps) => {
  const time = slot.scheduled_time ? convertToRegularTime(slot.scheduled_time) : '';
  const {
    patient: {
      id: patientId,
      last_name: patientLastName,
      first_name: patientFirstName,
    },
    id: appointmentId,
  } = slot;

  const selectPatientAction = () => {
    connectedSelectPatient(new SelectPatientPayload(slot));
    onCloseModal();
  };

  const selectAddReadingAction = () => {
    connectedSelectAddReading(new SelectAddReadingPayload(slot));
    onCloseModal();
  };

  const selectApptHistoryUrl = toDrChronoUrl(patientId ? `/misc/patient_appointments/${patientId}/` : '');
  const selectAddNotesUrl = toDrChronoUrl(`/clinical_note/edit/${appointmentId}/`);
  const selectEditApptUrl = toDrChronoUrl(`/appointments/${appointmentId}/`);

  return open ? (
    <Modal onCloseModal={onCloseModal}>
      <Details>
        <PatientName>{`${patientFirstName} ${patientLastName}`}</PatientName>
        <ApptTime>{time}</ApptTime>
        <ApptType>{appointmentTypeLookup(appointmentTypes, slot?.profile)}</ApptType>
      </Details>

      <Divider />

      <ActionContainer>
        <StyledLink to={`${ROUTE_ADD_RX}/${appointmentId}`}>
          <PatientAction onClick={selectPatientAction}>
            <IconWrapper><PrescriptionIcon /></IconWrapper>
            <ActionName>Input/Send Rx</ActionName>
          </PatientAction>
        </StyledLink>
        {allowTopconUpload &&
          <StyledLink to={`${ROUTE_UPLOAD_READING}/${appointmentId}`}>
            <PatientAction onClick={selectAddReadingAction}>
              <IconWrapper><QuickAddIcon /></IconWrapper>
              <ActionName data-cy="upload-rx-data">Upload data</ActionName>
            </PatientAction>
          </StyledLink>
        }
        <NavigationAction target="_blank" href={selectApptHistoryUrl}>
          <IconWrapper><ClockIcon /></IconWrapper>
          <ActionName>View Appt History</ActionName>
        </NavigationAction>
        <NavigationAction target="_blank" href={selectAddNotesUrl}>
          <IconWrapper><ReviewIcon /></IconWrapper>
          <ActionName>Add Clinical Notes</ActionName>
        </NavigationAction>
        <NavigationAction target="_blank" href={selectEditApptUrl}>
          <IconWrapper><SettingsIcon /></IconWrapper>
          <ActionName>Edit Appointment</ActionName>
        </NavigationAction>
      </ActionContainer>

    </Modal>
  ) : null;
};

export default AppointmentModal;
