import { css } from '@emotion/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { NotificationsIcon } from '@warbyparker/retail-design-system';
import styled from '@emotion/styled';

import { AUTH_REQUEST } from './redux/auth';
import { loading } from './assets/styles/loading';
import { useAppSelector } from './hooks';
import AppointmentList from './components/AppointmentList';
import RxType from './components/RxType';
import UploadReading from './components/UploadReading';
import { ROUTE_ADD_RX, ROUTE_UPLOAD_READING, ROUTE_ROOT } from './routes';
import COLORS from './assets/styles/colors';
import RxApi from './service-clients/rx-api';

const FloatButton = styled('button')`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: ${COLORS.BLUES_BLUE};
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${COLORS.BLUE_HOVER};
  }
`;

const App = () => {
  const { me, jwt, error } = useAppSelector(state => state.auth);
  const dispatch = useDispatch();

  const onCheckRxAPIConnection = async () => {
    const data = await RxApi.verifyHealthCheck();
    console.log('Data from Rx API', data);
  };

  if (!jwt) {
    dispatch({ type: AUTH_REQUEST });
    return (
      <div css={loading}>
        <img
          alt="spinner"
          src="https://www.warbyparker.com/assets/img/icons/actions/spinner.png"
        />
        <p>Loading&hellip;</p>
      </div>
    );
  }

  if (!me) {
    return (
      <div css={loading}>
        <img
          alt="spinner"
          src="https://www.warbyparker.com/assets/img/icons/actions/spinner.png"
        />
        <p>Loading&hellip;</p>
      </div>
    );
  }

  if (error) {
    return <h1>{error}</h1>;
  }

  return (
    <div css={css`padding-bottom: 100px;`}>
      <Switch>
        <Route path={ROUTE_ROOT} component={AppointmentList} exact />
        <Route path={`${ROUTE_ADD_RX}/:appointmentId`} component={RxType} exact />
        <Route path={`${ROUTE_UPLOAD_READING}/:appointmentId`} component={UploadReading} exact />
      </Switch>
      <Redirect from="*" to={ROUTE_ROOT} />
      <FloatButton onClick={onCheckRxAPIConnection}>
        <NotificationsIcon />
      </FloatButton>
    </div>
  );
};

export default App;
